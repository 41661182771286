import React from 'react';
import {
    BrowserRouter as Router,
    Route,
    Routes
} from "react-router-dom";
import Login from '../componets/Login/Login';
import Register from '../componets/Register/Register';
import NotFound from '../componets/Layout/NotFound';
import Home from '../componets/Home/Home';
import Account from '../componets/Account/Account';
import AccountAffiliate from '../componets/Account/AccountAffiliate';
import AccountWallet from '../componets/Account/AccountWallet';
import AccountProfile from '../componets/Account/AccountProfile';
import AcountStaking from '../componets/Account/AcountStaking';
import AccountGame from '../componets/Account/AccountGame';
import Resetpass from '../componets/Login/Resetpass';

function UserRoutes(props) {

    return (
        <>
            <Routes>                
            <Route path="/affiliate" element={<AccountAffiliate />} />
                <Route path="/wallet" element={<AccountWallet />} />
                <Route path="/profile" element={<AccountProfile />} />
                <Route path="/account" element={<Account />} />
                <Route path="/staking" element={<AcountStaking />} />
                <Route path="/game" element={<AccountGame />} />
                <Route path="/login" element={<Login />} />
                <Route path="/resetpassword" element={<Resetpass />} />   
                <Route path="/register" element={<Register />} />              
                <Route path="/" element={<Home />} />
                <Route path="*" element={<NotFound />} />
                
            </Routes>

        </>
    );
}

export default UserRoutes;